import React from 'react';
import { Button, Typography } from 'glints-aries/lib/@next';
import { VoidCallback } from 'react-dropzone-component';
import { FormattedMessage } from 'react-intl';

import { CompactOpportunityCard } from 'src/components/Cards/CompactOpportunityCard';
import { Company, Job } from 'src/global/models';

import { UTM_REFERRER } from '../../../Opportunities/constants';
import * as S from '../../CompanyPage.sc';

interface Props {
  company?: Company;
  relevantJobs?: Job[];
  scrollToSimilarCompaniesSection?: VoidCallback;
}

export const JobsSection: React.FC<React.PropsWithChildren<Props>> = ({
  company,
  relevantJobs,
  scrollToSimilarCompaniesSection,
}) => {
  return (
    <Choose>
      <When condition={relevantJobs && relevantJobs?.length > 0}>
        <S.JobList>
          <S.CardContainer>
            {relevantJobs.map((opportunity, index) => (
              <CompactOpportunityCard
                key={opportunity.id}
                index={`${index}`}
                company={company}
                opportunity={opportunity}
                // TODO: remove this when CompactOpportunityCard is migrated to TS
                // @ts-ignore
                referrer={UTM_REFERRER.COMPANY_PROFILE}
              />
            ))}
          </S.CardContainer>
        </S.JobList>
      </When>
      <Otherwise>
        <S.EmptyCardContainer>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="text-no-job-right-now"
              defaultMessage="This company does not have any job right now"
            />
          </Typography>
          <Button onClick={scrollToSimilarCompaniesSection}>
            <Typography variant="button">
              <FormattedMessage
                id="text-explore-similar-companies"
                defaultMessage="Explore Similar Companies"
              />
            </Typography>
          </Button>
        </S.EmptyCardContainer>
      </Otherwise>
    </Choose>
  );
};
